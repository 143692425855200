import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Area,
  AreaChart,
  Brush,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

function LiveAreaChart(props) {
  const colorYaxis = props.theme === 'light' ? null : 'white';
  const [data, setData] = useState([]);

  const fetchPortFolioData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/portfolios/?skip=0&limit=10`
      );
      if (response.status === 200) {
        const portfolioData =
          response?.data?.data?.length &&
          response.data.data.find((data) => data.id === props.id);
        fetchData(portfolioData ? 'portfolio' : 'assets');
      }
    } catch (error) {
      console.error('Error fetching portfolio data:', error);
      return [];
    }
  };

  const fetchData = async (type) => {
    try {
      let response;
      if (type === 'assets') {
        response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/assets/${props?.id}/forecast_graph?skip=0&limit=10`
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/portfolios/${props?.id}/forecast_graph?skip=0&limit=10`
        );
      }
      debugger;
      if (response.status === 200) {
        // Assuming data structure is a single object with multiple keys and values
        const formattedData = response.data?.data?.map((entry) => ({
          date: entry.date,
          'MQE Strategy': entry.val_1,
          'S&P 500': entry.val_2,
          'Exchange Rate': entry.val_3,
        }));
        console.log('formattedData', formattedData);
        setData(formattedData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (props?.id) {
      fetchPortFolioData();
    }
  }, []);

  return (
    <>
      <ResponsiveContainer width="95%" height={400}>
        <AreaChart
          width={600}
          height={200}
          data={data}
          margin={{ top: 10, right: 30, left: 30, bottom: 30 }}
        >
          <defs>
            <linearGradient id="colorVolatility" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorMomentum" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorRisk" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#ffc658" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            name="Date"
            tick={{ textAnchor: 'end', angle: -30 }}
            height={80}
            style={{ fill: props.theme === 'light' ? null : 'white' }}
          >
            <Label
              value="Date"
              offset={-20}
              position="bottom"
              style={{ fill: props.theme === 'light' ? null : 'white' }}
            />
          </XAxis>
          <YAxis
            style={{ fill: props.theme === 'light' ? null : 'white' }}
            label={{
              fill: colorYaxis,
              value: 'Metrics Value',
              angle: -90,
              position: 'insideBottomLeft',
              x: -10,
            }}
          ></YAxis>
          <Tooltip />
          <Legend />
          <Brush dataKey="date" height={25} stroke="#8884d8" />
          <Area
            type="monotone"
            dataKey="MQE Strategy"
            stroke="#8884d8"
            fill="url(#colorVolatility)"
          />
          <Area
            type="monotone"
            dataKey="S&P 500"
            stroke="#82ca9d"
            fill="url(#colorMomentum)"
          />
          <Area
            type="monotone"
            dataKey="Exchange Rate"
            stroke="#ffc658"
            fill="url(#colorRisk)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
}

export default LiveAreaChart;
