import moment from 'moment';
import React from 'react';
import { CardBody, CardTitle, CardText, CardSubtitle } from 'reactstrap';

function HeroCardBody(props) {
  return (
    <CardBody>
      <CardTitle
        className={
          props.exitedAt
            ? 'mb-4 text-muted mae-signal-text'
            : props.signal === 'LONG'
            ? 'mb-4 green-text mae-signal-text'
            : props.signal === 'SHORT'
            ? 'mb-4 red-text mae-signal-text'
            : 'mb-4 mae-signal-text'
        }
      >
        <h4>
          {props.exitedAt
            ? 'NEUTRAL'
            : props.signal === 'LONG'
            ? 'LONG'
            : props.signal === 'SHORT'
            ? 'SHORT'
            : 'NA'}
        </h4>
      </CardTitle>
      {!props.exitedAt && (
        <>
          <CardSubtitle className="mae-return-text">
            Return Since Signal
          </CardSubtitle>
          <h3
            className={
              parseFloat(props.interest) >= 0
                ? 'green-text mb-4 mae-interest-text'
                : 'red-text mb-4 mae-interest-text'
            }
          >
            {props.interest}%
          </h3>
          <CardText className="mae-date-text">
            Entered on <br />{' '}
            <b>{moment(props.createdAt).format('MM/DD/YYYY')}</b>
          </CardText>
        </>
      )}
      {props.exitedAt && (
        <>
          <CardSubtitle className="mae-return-text">&nbsp;</CardSubtitle>
          <h3
            className={
              parseFloat(props.interest) >= 0
                ? 'green-text mb-4 mae-interest-text'
                : 'red-text mb-4 mae-interest-text'
            }
          >
            &nbsp;
          </h3>
          <CardText className="mae-date-text">
            &nbsp;
            <br />
            &nbsp;
          </CardText>
        </>
      )}
    </CardBody>
  );
}

export default HeroCardBody;
