import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'reactstrap';

function RecentForecastTable(props) {
  const [data, setData] = useState([]);
  const fetchPortFolioData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/portfolios/?skip=0&limit=10`
      );
      if (response.status === 200) {
        const findPortfolio =
          response?.data?.data?.length &&
          response.data.data.find((data) => data.id === props.id);
        fetchData(findPortfolio ? 'portfolio' : 'assets');
      }
    } catch (error) {
      console.error('Error fetching portfolio data:', error);
      return [];
    }
  };
  const fetchData = async (type) => {
    try {
      let response;
      if (type === 'assets') {
        response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/assets/${props?.id}/forecasts?skip=0&limit=10`
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/portfolios/${props?.id}/forecasts?skip=0&limit=10`
        );
      }
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    if (props?.id) {
      fetchPortFolioData();
    }
  }, [props]);

  var filtered = data;

  // TODO: Filter data based on forecastTerm
  // var filtered =
  //   value &&
  //   value.docs.filter(
  //     (doc) =>
  //       doc.data().horizon ===
  //       (props.forecastTerm === 12 ? 12 : props.forecastTerm === 1 ? 1 : null)
  //   );

  return (
    <Row className="p-3">
      <Col>
        <Table striped bordered responsive className="forecasts-table">
          <thead>
            <tr>
              <th>Signal</th>
              <th>Return</th>
              <th>Date of Signal</th>
              <th>End of Signal</th>
            </tr>
          </thead>
          <tbody>
            {data && filtered.length > 0 ? (
              <>
                {filtered.map((doc) => (
                  <tr key={doc.id}>
                    <td>
                      <span
                        className={
                          doc.signal === 'LONG'
                            ? 'mb-4 green-text'
                            : doc.signal === 'SHORT'
                            ? 'mb-4 red-text'
                            : 'mb-4'
                        }
                      >
                        {doc.signal || 'NA'}
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          parseFloat(doc.interest) >= 0
                            ? 'green-text'
                            : 'red-text'
                        }
                      >
                        {doc.interest}%
                      </span>
                    </td>
                    <td>
                      {doc.date_of_forecast
                        ? new Date(doc.date_of_forecast).toLocaleDateString(
                            'en-US'
                          )
                        : 'NA'}
                    </td>
                    <td>
                      {doc.date_of_exit
                        ? new Date(doc.date_of_exit).toLocaleDateString('en-US')
                        : ''}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="4">Coming Soon</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}

export default RecentForecastTable;
