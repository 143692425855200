import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardImg, CardSubtitle } from 'reactstrap';
import HeroCardBody from './HeroCardBody';

function HeroCard(props) {
  const [data, setData] = useState([]);
 const fetchPortFolioData = async () => {
   try {
     const response = await axios.get(
       `${process.env.REACT_APP_BASE_URL}/portfolios/?skip=0&limit=10`
     );
     if (response.status === 200) {
       const findPortfolio =
         response?.data?.data?.length &&
         response.data.data.find((data) => data.id === props.id);
       fetchData(findPortfolio ? 'portfolio' : 'assets');
     }
   } catch (error) {
     console.error('Error fetching portfolio data:', error);
     return [];
   }
 };
 const fetchData = async (type) => {
   try {
     const response = await axios.get(
       `${process.env.REACT_APP_BASE_URL}/assets/${props?.id}/forecasts?skip=0&limit=10`
     );
     if (response.status === 200) {
       setData(response.data);
     }
   } catch (error) {
     console.error('Error fetching data:', error);
   }
 };
 useEffect(() => {
   if (props?.id) {
     fetchPortFolioData();
   }
 }, [props]);

 var filtered = data;
 // TODO: Filter data based on forecastTerm
 // var filtered =
 //   data &&
 //   data.filter(
 //     (doc) =>
 //       doc.horizon ===
 //       (props.forecastTerm === 12 ? 12 : props.forecastTerm === 1 ? 1 : null)
 // );
 return (
   <Card className="center text-center" key={props.id}>
     <div className="container-img">
       <CardImg
         style={{ opacity: 0.35 }}
         top
         width="100%"
         src={props.image}
         alt="Card image cap"
       />
       <h4 className="centered mae-currency-name maeCurrencyName">
         {props.title}
       </h4>
     </div>

     {filtered && filtered.length > 0 ? (
       <>
         {filtered.slice(0, 1).map((doc) => (
           <HeroCardBody
             key={doc.id}
             signal={doc.signal}
             interest={doc.interest}
             createdAt={doc.date_of_forecast}
             exitedAt={doc.date_of_exit}
           />
         ))}
       </>
     ) : (
       <>
         <CardBody className="no-data-card">
           <CardSubtitle>
             <h4>Coming Soon</h4>
           </CardSubtitle>
         </CardBody>
       </>
     )}
   </Card>
 );
}

export default HeroCard;
